<template>
  <div class="d-flex align-center flex-column submission-completed">
    <img
      alt="Form Successfully Submitted"
      src="./form-successfully-submitted.jpg"
    >
    <h2>{{ numberOfForms }} successfully submitted!</h2>
    <p>Thank you for completing the {{ numberOfForms }}. We look forward to seeing you at your appointment. </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ViewsConfirmation',
  computed: {
    ...mapGetters({
      forms: 'forms/forms'
    }),
    numberOfForms () {
      return `Form${this.forms.length > 1 ? 's' : ''}`
    }
  },
  mounted () {
    if (window.parentIFrame) {
      window.parentIFrame.scrollTo(0, 0)
    }
  }
}
</script>
<style lang="scss" scoped>
.submission-completed {
  height: 400px;
  justify-content: center;
  margin: auto;
  text-align: center;

  @include desktop() {
    max-width: 50vw;
  }
}
</style>
